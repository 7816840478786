import React from "react"
import {
  BasicResponsiveImage,
  Callout,
  PromoCode,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileImage,
  StandardTileName,
  StandardTileOverlay,
  StandardTilePromoCode,
  StandardTilePromoCodeLabel,
} from "@vp/swan"
// import TilePrice from "./TilePrice"
import { createOnClickTrackingHandler } from "./Analytics/trackingUtils"
import { CampaignCallout } from "@vp/campaign-callouts"

const Tile = ({ data, trackingConfiguration, loadingText, promoCodeText, locale, tenant, loadType }) => {
  const {
    calloutText,
    calloutColor,
    calloutVariant,
    promoCode,
    transformedUrl,
    productImage,
    productTileSubHeader,
    productTileHeader,
    mpv,
    tilePricingConfiguration,
  } = data
  const linkTrackingAttributes = trackingConfiguration ? trackingConfiguration.linkAttributes : {}
  return (
    <StandardTile
      component="a"
      marginBottom={0}
      href={transformedUrl.url}
      onClick={createOnClickTrackingHandler(trackingConfiguration, transformedUrl.url)}
      {...linkTrackingAttributes}
    >
      <StandardTileOverlay>
        {calloutText && (
          <>
            <Callout skin={calloutColor} variant={calloutVariant} className="snowflake-callout">
              {calloutText}
            </Callout>
          </>
        )}
        <CampaignCallout product={mpv?.mpvId ?? ""} />
      </StandardTileOverlay>
      <StandardTileImage>
        <BasicResponsiveImage aspectRatio={1} src={productImage.cloudinaryImage.transformedCloudinaryUrl} alt="" loading={loadType} />
      </StandardTileImage>
      <StandardTileContents paddingBottom={4}>
        <StandardTileName>{productTileHeader ?? mpv?.productName}</StandardTileName>
        {productTileSubHeader && <StandardTileDescription>{productTileSubHeader}</StandardTileDescription>}
        {promoCode && promoCode.trim() !== "" && (
          <StandardTilePromoCode>
            <StandardTilePromoCodeLabel>{promoCodeText}</StandardTilePromoCodeLabel>
            <PromoCode>{promoCode}</PromoCode>
          </StandardTilePromoCode>
        )}
        {/* <TilePrice mpv={mpv} showPrice={tilePricingConfiguration.showPrice} locale={locale} tenant={tenant} /> */}
      </StandardTileContents>
    </StandardTile>
  )
}

export default Tile
