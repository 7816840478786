const PAGE_NAME = "Special Offers"
const PAGE_STAGE = "Discover"
const REQUESTOR = "special-offers"

const FIRST_TILES = "firstTiles"
const SECOND_TILES = "secondTiles"
const THIRD_TILES = "thirdTiles"
const FOURTH_TILES = "fourthTiles"
const FIFTH_TILES = "fifthTiles"

module.exports = {
  PAGE_NAME,
  PAGE_STAGE,
  REQUESTOR,
  FIRST_TILES,
  SECOND_TILES,
  THIRD_TILES,
  FOURTH_TILES,
  FIFTH_TILES,
}
