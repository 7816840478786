import React from "react"
import { Box, Row, Column, H3 } from "@vp/swan"
import Banner from "./Banner"
import TilesList from "./TilesList"
import { ContentfulLink } from "@vp/mcs-react-tools"

const BannerTilesSection = props => {
  const bannerProps = props?.bannerData?.props
  const { tileImageLoadingStrategy } = props
  return (
    <Box mb="between-sections">
      {bannerProps && (
        <ContentfulLink id={props?.bannerData?.contentful_id}>
          <Banner trackingPageZone={props.bannerTrackingZone} bannerProps={bannerProps} sectionId={props.sectionId} locale={props.locale} />
        </ContentfulLink>
      )}
      {props.leadInTitle && (
          <Row
            data-testid="spacing"
            marginTop={{ xs: 7, md: !props.bannerData ? 0 : 9 }}
            paddingLeft={{ md: 2 }}
            marginLeft={{ xs: 4, md: 7 }}
            marginBottom={{ xs: 4, md: 5 }}
          >
            <Column span={12} padding={0}>
              {props.leadInTitle.trim().length > 0 && (
                <H3 margin={0} fontSize={"x2large"} fontWeight="bold" fontSkin={"title-section"}>
                  {props.leadInTitle}
                </H3>
              )}
            </Column>
          </Row>
      )}
      {props.tileData && (
        <Row
          marginTop={!props.leadInTitle ? { xs: 7, md: 9 } : {}}
          paddingLeft={{ md: 1 }}
          marginBottom={{ xs: 5, md: 0 }}
          paddingRight={{ md: 1 }}
          marginLeft={{ xs: 2, md: 6 }}
          marginRight={{ xs: 2, md: 6 }}
          component="ul"
        >
            <TilesList
              loadType={tileImageLoadingStrategy}
              list={props.tileData}
              locale={props.locale}
              tenant={props.tenant}
              pageEnablePrice={props.enablePrice}
              promoCodeText={props.promoCodeText}
              loadingText={props.loadingText}
              trackingPageZone={props.tileTrackingZone}
            />
        </Row>
      )}
    </Box>
  )
}

export default BannerTilesSection
