import React from 'react'
import {
  Card,
  FlexBox,
  Typography,
  SWAN_STYLE_KEY_MAP,
  SWAN_FONT_KEY_MAP,
  SwanFontNameEnum
} from '@vp/swan'
import { useSwanStyleKeys, useUserContextTracker, useSwanFontKeys } from '@vp/ubik-context'
import { IdentityProvider } from "./hooks/IdentityContext"
import SpecialOffers from './components/SpecialOffers'
import { TrackingProvider } from "./hooks/TrackingContext"
import { PricingProvider } from "./hooks/price/PricingContext"
import { CampaignProvider } from '@vp/campaign-callouts'
import { ContentfulContextProvider } from '@vp/mcs-react-tools'
// import getMpvIdsUsed from "./utils/getMpvIdsUsed"


type DictionaryEntry = {
    [locale: string]: string
}

export type AppProps = {
  locale: string,
  tenant: string,
  pageData: object,
  dictionaryEntries: DictionaryEntry,
  merchandisingCampaign: string,
  experimentKey: string,
  variationKey: string,
  decision: string,
  localizations: {
    [key: string]: string
  },
  contentfulMetadata: {
    spaceId: string,
    environment: string,
    contentful_id: string
  },
}

function App(props: AppProps) {
  // TODO add the bookends style keys
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.pricing,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.banner,
    SWAN_STYLE_KEY_MAP.table
  ])
  useSwanFontKeys([
    SwanFontNameEnum.GraphikRegular,
    SwanFontNameEnum.GraphikMedium,
    SwanFontNameEnum.Tiempos,
    SwanFontNameEnum.TiemposRegular
  ])

  const data = {
    pageData: props.pageData,
    merchandisingCampaign: props.merchandisingCampaign,
    // promobarConfig: props.data.promobarConfig,
    promoCodeText: props.dictionaryEntries.promoCodeText,
    localizations: props.localizations,
    // search: props.location.search,
    locale: props.locale,
    tenant: props.tenant,
    debugData: {
      experimentKey: props.experimentKey,
      variationKey: props.variationKey,
      decision: props.decision,
      contentfulMetadata: props.contentfulMetadata,
    },
  }

  trackUsedContext(props)

  return (
    <ContentfulContextProvider space={props.contentfulMetadata.spaceId}>
      <TrackingProvider canonicalUrl="https://www.vistaprint.com/offers">
        <CampaignProvider tenant={props.tenant} locale={props.locale}>
          {/* <PricingProvider
                tenant="vistaprint"
                locale="en-us"
                market={data.locale.slice(-2).toUpperCase()}
                pricingEnabled
                mpvIds={['something']}
                > */}
          <SpecialOffers data={data} />
          {/* </PricingProvider> */}
        </CampaignProvider>
      </TrackingProvider>
    </ContentfulContextProvider>
  )
}

function trackUsedContext(props: AppProps) {
  const tracker = useUserContextTracker()
  if (props.experimentKey) {
    tracker({
      type: 'experiment',
      name: props.experimentKey,
      variation: props.variationKey
    })
  }
  if(props.decision) {
    tracker({
      type: 'treatment',
      name: props.decision
    })
  }
  if(props.merchandisingCampaign) {
    tracker({
      type: 'campaign',
      name: props.merchandisingCampaign
    })
  }
}

export default App
