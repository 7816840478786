export const TrackingPageZone = {
  FirstBanner: "First Hero",
  FirstTiles: "First Tiles",
  SecondBanner: "Second Hero",
  SecondTiles: "Second Tiles",
  ThirdBanner: "Third Hero",
  ThirdTiles: "Third Tiles",
  FourthBanner: "Fourth Hero",
  FourthTiles: "Fourth Tiles",
  FifthBanner: "Fifth Hero",
  FifthTiles: "Fifth Tiles",
  ExtraCoupons: "Extra Coupons",
}
