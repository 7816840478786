import { FIFTH_TILES, FIRST_TILES, FOURTH_TILES, SECOND_TILES, THIRD_TILES } from "./constants"
export const eagerLoadFirstSetOfTiles = pageProductTiles => {
  const firstNonEmptyIndex = pageProductTiles.findIndex(array => array.length > 0)
  if (firstNonEmptyIndex === -1) return "eager"

  switch (firstNonEmptyIndex) {
    case 0:
      return FIRST_TILES
    case 1:
      return SECOND_TILES
    case 2:
      return THIRD_TILES
    case 3:
      return FOURTH_TILES
    default:
      return FIFTH_TILES
  }
}