import React from "react";
import parse from "html-react-parser";
import { Box, UncustomizableMarkup } from "@vp/swan";

const SpecialOffersDetail = (props) => {
  let { content } = props;

  return (
    <UncustomizableMarkup>
      {content ? parse(content) : null}
    </UncustomizableMarkup>
  );
};

export default SpecialOffersDetail;
