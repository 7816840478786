import React from "react"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import { useTrackingContext } from "../hooks/TrackingContext"
import { createOnClickTrackingHandler } from "./Analytics/trackingUtils"

const MarkdownWithTrackableLinks = ({ html, getTrackingInfo }) => {
  const { sourcePagePath } = useTrackingContext()

  const options = {
    replace: domNode => {
      const props = attributesToProps(domNode.attribs)

      if (domNode.name === "a" && props.href) {
        const ctaValue = domNode.children.length > 0 && domNode.children[0].type === "text" ? domNode.children[0].data : ""
        const trackingConfiguration = getTrackingInfo(sourcePagePath, props.href, ctaValue)
        const linkTrackingAttributes = trackingConfiguration ? trackingConfiguration.linkAttributes : {}

        return (
          <a {...props} onClick={createOnClickTrackingHandler(trackingConfiguration, props.href)} {...linkTrackingAttributes}>
            {domToReact(domNode.children)}
          </a>
        )
      }
    },
  }
  return html ? parse(html, options) : null
}

export default MarkdownWithTrackableLinks
