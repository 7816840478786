import { TrackingPageZone } from "./trackingTypes"
import { PAGE_NAME } from "../../utils/constants"

export const getCtaTrackingConfig = ({ sourcePagePath, destinationUrl, pageZone, linkIdentifier, ctaPosition }) => {
  const parsedDestinationPagePath = removeQueryParamsFrom(destinationUrl)
  const eventDetail = `${sourcePagePath};${parsedDestinationPagePath};${pageZone};${linkIdentifier}`
  const navigationDetail =
    ctaPosition && ((typeof ctaPosition === "number" && ctaPosition > 0) || typeof ctaPosition === "string")
      ? `${linkIdentifier}:${ctaPosition}`
      : linkIdentifier
  return {
    eventName: "Navigation Clicked",
    properties: {
      category: PAGE_NAME,
      label: `Content:${pageZone}`,
      eventDetail,
      navigationDetail,
    },
    linkAttributes: {
      "data-section": `Content:${pageZone}`,
      "data-position": ctaPosition,
      "data-translation": linkIdentifier,
    },
  }
}

const removeQueryParamsFrom = pagePath => {
  if (typeof window !== "undefined") {
    return new URL(pagePath, window.location.origin).pathname
  }

  return pagePath
}

export const getTileCtaTrackingConfig = (sourcePagePath, destinationUrl, pageZone, linkIdentifier, ctaPosition) => {
  return getCtaTrackingConfig({ sourcePagePath, destinationUrl, pageZone, linkIdentifier, ctaPosition })
}

export const getExtraCouponsTrackingConfig = (sourcePagePath, destinationUrl, linkIdentifier) => {
  return getCtaTrackingConfig({ sourcePagePath, destinationUrl, pageZone: TrackingPageZone.ExtraCoupons, linkIdentifier })
}
