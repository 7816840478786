import React from "react"
import { UncustomizableMarkup } from "@vp/swan"
import MarkdownWithTrackableLinks from "./MarkdownTableWithTrackableLinks"
import { getExtraCouponsTrackingConfig } from "./Analytics/trackingConfig"

const ExtraCouponsTable = ({ extraCouponsContent }) => {
  return (
      <UncustomizableMarkup>
        <MarkdownWithTrackableLinks html={extraCouponsContent} getTrackingInfo={getExtraCouponsTrackingConfig} />
      </UncustomizableMarkup>
  )
}

export default ExtraCouponsTable
