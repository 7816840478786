import React from "react";

import { Column, H2, Row } from "@vp/swan";
import ExtraCouponsTable from "./ExtraCouponsTable";

const ExtraCouponsContent = ({ extraCouponsLabel, extraCouponsContent }) => {
  return (
    <>
      {extraCouponsLabel && (
        <Row data-testid="spacing" marginTop={{ xs: 3, md: 0 }}>
          <Column
            span={8}
            offset={2}
            paddingLeft={{ xs: 4, md: 0 }}
            paddingBottom={{ md: 2 }}
            paddingTop={{ xs: 7, md: 9 }}
          >
            {extraCouponsLabel.trim().length > 0
              && (
                <H2 fontSize={"x2large"} fontWeight="bold" textAlign="left">
                  {extraCouponsLabel}
                </H2>
              )}
          </Column>
        </Row>
      )}

      {extraCouponsContent && (
        <Row textAlign="left">
          <Column
            paddingLeft={{ xs: 4, md: 0 }}
            span={8}
            offset={2}
            marginBottom={{ xs: 0, md: 9 }}
          >
            <ExtraCouponsTable extraCouponsContent={extraCouponsContent} />
          </Column>
        </Row>
      )}
    </>
  );
};

export default ExtraCouponsContent;
