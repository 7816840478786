import React from "react"
import { BannerWrapper } from "@vp/banner-wrapper"
import { Column, Row } from "@vp/swan"
import { useTrackingContext } from "../hooks/TrackingContext"
import { getCtaTrackingConfig } from "./Analytics/trackingConfig"
import { createOnClickTrackingHandler } from "./Analytics/trackingUtils"

const Banner = ({ bannerProps, trackingPageZone, sectionId, locale }) => {
  const { sourcePagePath } = useTrackingContext()

  const trackingMethod = (event, { destinationUrl, linkIdentifier, ctaPosition, openInNewTab }) => {
    const trackingConfig = getCtaTrackingConfig({
      sourcePagePath,
      destinationUrl: destinationUrl ?? "",
      pageZone: trackingPageZone,
      linkIdentifier,
      ctaPosition,
    })
    if (!trackingConfig) {
      return
    }
    const trackingAndNavigation = createOnClickTrackingHandler(trackingConfig, destinationUrl, openInNewTab)
    if (trackingAndNavigation) {
      trackingAndNavigation(event)
    }
  }

  return (
    bannerProps && (
      <Row marginBottom={"2"}>
        <Column span={12} padding={0}>
          <BannerWrapper id={sectionId} bannerProps={bannerProps} trackingHandler={trackingMethod} locale={locale} />
        </Column>
      </Row>
    )
  )
}

export default Banner