import React, { useState, useEffect } from "react"
import { Column, BoundedContent, GridContainer, Row, H1, H2, H3 } from "@vp/swan"
import { TrackingPageZone } from "./Analytics/trackingTypes"
import ExtraCouponsContent from "./ExtraCouponsContent.jsx"
import { FaqSection } from "./FaqSection"
import SpecialOffersDetail from "./SpecialOffersDetail"
import BannerTilesSection from "./BannerTilesSection"
import { isBrowser } from "../utils/shared"
import { FIFTH_TILES, FIRST_TILES, FOURTH_TILES, SECOND_TILES, THIRD_TILES } from "../utils/constants"
import { eagerLoadFirstSetOfTiles } from "../utils/tileUtils"
import { DebugSection } from "@vp/mcs-react-tools"

const SpecialOffers = props => {
  const [hashExists, setHashExists] = useState(false);

  const checkHash = () => {
    setHashExists(isBrowser() && window.location.hash !== '');
  };

  useEffect(() => {
    checkHash();
  }, []);

  const { pageData, promoCodeText, localizations, locale, tenant } = props.data
  const {
    title,
    subtitle,
    enablePrice,
    firstBanner,
    secondBanner,
    thirdBanner,
    fourthBanner,
    fifthBanner,
    mainTiles,
    secondaryTiles,
    thirdTiles,
    fourthTiles,
    fifthTiles,
    content,
    contentLabel,
    firstLeadIn,
    secondLeadIn,
    thirdLeadIn,
    fourthLeadIn,
    fifthLeadIn,
    faqLabel,
    faqList,
    extraCouponsLabel,
    extraCouponsContent,
  } = pageData

  const pageProductTiles = [mainTiles ?? [], secondaryTiles ?? [], thirdTiles ?? [], fourthTiles ?? [], fifthTiles ?? []]
  const eagerLoadTileSection = eagerLoadFirstSetOfTiles(pageProductTiles)

  const {
    firstBannerSectionId,
    secondBannerSectionId,
    thirdBannerSectionId,
    fourthBannerSectionId,
    fifthBannerSectionId,
    offerDetailsSection,
    loadingText,
  } = localizations

  const debugAvailability = {
    merchandisingCampaign: props.data.merchandisingCampaign,
    experimentKey: props.data.debugData?.experimentKey,
    variationKey: props.data.debugData?.variationKey,
    decision: props.data.debugData?.decision,
  }

  const offersSharedLayout = [
    {
      tileTargetPath: FIRST_TILES,
      bannerData: firstBanner,
      bannerTrackingZone: TrackingPageZone.FirstBanner,
      tileTrackingZone: TrackingPageZone.FirstTiles,
      sectionId: firstBannerSectionId,
      leadInTitle: firstLeadIn,
      tiles: mainTiles,
    },
    {
      tileTargetPath: SECOND_TILES,
      bannerData: secondBanner,
      bannerTrackingZone: TrackingPageZone.SecondBanner,
      tileTrackingZone: TrackingPageZone.SecondTiles,
      sectionId: secondBannerSectionId,
      leadInTitle: secondLeadIn,
      tiles: secondaryTiles,
    },
    {
      tileTargetPath: THIRD_TILES,
      bannerData: thirdBanner,
      bannerTrackingZone: TrackingPageZone.ThirdBanner,
      tileTrackingZone: TrackingPageZone.ThirdTiles,
      sectionId: thirdBannerSectionId,
      leadInTitle: thirdLeadIn,
      tiles: thirdTiles,
    },
    {
      tileTargetPath: FOURTH_TILES,
      bannerData: fourthBanner,
      bannerTrackingZone: TrackingPageZone.FourthBanner,
      tileTrackingZone: TrackingPageZone.FourthTiles,
      sectionId: fourthBannerSectionId,
      leadInTitle: fourthLeadIn,
      tiles: fourthTiles,
    },
    {
      tileTargetPath: FIFTH_TILES,
      bannerData: fifthBanner,
      bannerTrackingZone: TrackingPageZone.FifthBanner,
      tileTrackingZone: TrackingPageZone.FifthTiles,
      sectionId: fifthBannerSectionId,
      leadInTitle: fifthLeadIn,
      tiles: fifthTiles,
    },
  ]

  const bannerSectionIds = [firstBannerSectionId, secondBannerSectionId, thirdBannerSectionId, fourthBannerSectionId, fifthBannerSectionId]
  const offersSharedLayoutWithBannerSectionId = addBannerSectionIdAndRemoveEmptySections({ offersSharedLayout, bannerSectionIds })

  const renderSpecialOffersContent = () => {
    return offersSharedLayoutWithBannerSectionId.map((item, index) => (
      <div key={offersSharedLayoutWithBannerSectionId.sectionId}>
        <BannerTilesSection
          tileImageLoadingStrategy={eagerLoadTileSection === item.tileTargetPath || hashExists ? "eager" : "lazy"}
          tileTargetPath={item.tileTargetPath}
          tileElementName={"Tiles list"}
          leadInTitle={item.leadInTitle}
          sectionId={item.sectionId}
          tileData={item.tiles}
          bannerData={item.bannerData}
          loadingText={loadingText}
          tileTrackingZone={item.tileTrackingZone}
          bannerTrackingZone={item.bannerTrackingZone}
          promoCodeText={promoCodeText}
          enablePrice={enablePrice}
          pageData={pageData}
          locale={locale}
          tenant={tenant}
        />
      </div>
    ))
  }

  const search = isBrowser() && window.location.search

  return (
    <BoundedContent paddingX={0}>
      <GridContainer>
        <Row
          marginLeft={{ xs: "5", md: "8" }}
          marginRight={{ xs: "5", md: "8" }}
          marginTop={{ xs: "5", md: "7" }}
          marginBottom={{ xs: "3", md: "7" }}
        >
          <Column span={12} padding={0}>
            <DebugSection
              contentfulMetadata={props.data.debugData?.contentfulMetadata }
              availability={debugAvailability}
              search={search}
            />
              <H1 fontSize={"x3large"} fontWeight="bold" mb={"4"} fontSkin={"title-headline"}>
                {title}
              </H1>
            {subtitle && (
                <H2 fontSize={"large"} fontWeight="normal" mb={"0"} fontSkin={"body-standard"}>
                  {subtitle}
                </H2>
            )}
          </Column>
        </Row>
        {renderSpecialOffersContent()}


        <ExtraCouponsContent extraCouponsContent={extraCouponsContent} extraCouponsLabel={extraCouponsLabel} />
        {faqLabel && !!faqList?.length && (
            <Row marginTop={{ xs: 7, md: 0 }} marginBottom={{ xs: 7, md: 9 }} backgroundColor="warm-white">
              <FaqSection label={faqLabel} list={faqList} />
            </Row>
        )}
        {contentLabel && (
          <Row>
            <Column span={8} push={2} paddingX={{xs: 5}}>
              <H3
                textAlign="left"
                marginBottom={0}
                fontSize={"large"}
                fontWeight="bold"
                data-elementname="Offer Details Section"
                id={offerDetailsSection}
              >
                {contentLabel}
              </H3>
              <SpecialOffersDetail content={content} />
            </Column>
          </Row>
        )}
      </GridContainer>
    </BoundedContent>
  )
}

export default SpecialOffers

const addBannerSectionIdAndRemoveEmptySections = ({ offersSharedLayout, bannerSectionIds }) => {
  return offersSharedLayout.map(data => (data.bannerData ? { ...data, sectionId: bannerSectionIds.shift() } : data)).filter(data => data.bannerData || data.tiles)
}
