import * as React from "react"
import parse from "html-react-parser"
import { UncustomizableMarkup, H2, Accordion, Collapsible, CollapsibleSummary, CollapsibleSummaryButton, CollapsibleContent, Column, Box } from "@vp/swan"
import { useOpenAllFaqs } from "../hooks/useOpenAllFaqs"

const FaqSection = ({ label, list }) => {
  const collapsibleIdPrefix = "FAQ"
  const faqState = useOpenAllFaqs(collapsibleIdPrefix, list.length)
  const [expandedCollapsibles, setExpandedCollapsibles] = React.useState({})

  React.useEffect(() => {
    setExpandedCollapsibles({ ...faqState })
  }, [faqState])

  return (
    <>
      {label && (
        <Column span={8} offset={2} paddingLeft={{ xs: 4, md: 2 }} paddingBottom={{ md: 2 }} paddingTop={{ xs: 7, md: 9 }}>
          <H2 fontSkin={"title-section"} fontWeight="bold" textAlign="left">
            {label}
          </H2>
        </Column>
      )}

      {list && (
        <Column paddingLeft={{ xs: 4, md: 2 }} paddingRight={{ xs: 4, md: 2 }} span={8} offset={2} backgroundColor="warm-white" pb={8}>
          <Accordion
            component="ul"
            marginY={0}
            expandedCollapsibles={expandedCollapsibles}
            onRequestExpandedChange={(collapsibleId, expanded) => {
              setExpandedCollapsibles(prevValue => ({
                ...prevValue,
                [collapsibleId]: expanded,
              }))
            }}
          >
            {list.map((elem, index) => (
                <Collapsible
                  component="li"
                  defaultExpanded
                  backgroundColor="warm-white"
                  collapsibleId={`${collapsibleIdPrefix} #${index + 1}`}
                >
                  <CollapsibleSummary>
                    <CollapsibleSummaryButton>{elem.question}</CollapsibleSummaryButton>
                  </CollapsibleSummary>
                  <CollapsibleContent backgroundColor="warm-white">
                    <Box padding={1}>
                      <UncustomizableMarkup>{elem?.answer ? parse(elem.answer) : null}</UncustomizableMarkup>
                    </Box>
                  </CollapsibleContent>
                </Collapsible>
            ))}
          </Accordion>
        </Column>
      )}
    </>
  )
}

const getStructuredData = questions => {
  return {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity: buildQuestions(questions),
  }
}

const buildQuestions = questions => {
  if (questions && questions.length > 0) {
    return questions
      .filter(q => q.answer && q.question)
      .map(q => {
        return {
          "@type": "Question",
          name: q.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: q.answer,
          },
        }
      })
  }
  return []
}

export { FaqSection, getStructuredData }
