import React from "react"
import { Column } from "@vp/swan"
import Tile from "./Tile"
import { getUrlIdForTracking } from "../utils/getUrlIdForTracking"
import { getTileCtaTrackingConfig } from "./Analytics/trackingConfig"
import { useTrackingContext } from "../hooks/TrackingContext"

const TilesList = props => {
  const { sourcePagePath } = useTrackingContext()
  const trackingPageZone = props.trackingPageZone
  const getTrackingConfiguration = (tile, position) => {
    const urlId = getUrlIdForTracking(tile.transformedUrl)
    const ctaValue = tile.mpv?.mpvId || tile.merchandisingElement?.mpvId || urlId || tile.productTileHeader
    return getTileCtaTrackingConfig(sourcePagePath, tile.transformedUrl.url, trackingPageZone, ctaValue, position)
  }

  let columnSpan = props.list && props.list.length % 6 === 0 ? 2 : 3

  return (
    <>
      {props.list &&
        props.list.map((tile, index) => (
          <Column component="li" span={columnSpan} paddingLeft={{ xs: 2, md: 3 }} spanXs={6} key={index} marginBottom={"between-subsections"}>
              <Tile
                loadType={props.loadType}
                data={tile}
                pageEnablePrice={props.pageEnablePrice}
                locale={props.locale}
                tenant={props.tenant}
                promoCodeText={props.promoCodeText}
                loadingText={props.loadingText}
                trackingConfiguration={getTrackingConfiguration(tile, index + 1)}
              />
          </Column>
        ))}
    </>
  )
}

export default TilesList
