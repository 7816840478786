import React from 'react'
import { Fragment as Root, type Props } from '../fragment'
import { clientMount } from '@vp/ubik-fragment-react'
import type { Mount } from '@vp/ubik-fragment-types'

export const mount: Mount<Props> = async (rootElement, renderProps) => {
  return clientMount(<Root {...renderProps} />, {
    rootElement,
  })
}
